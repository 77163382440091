import type { RenderLoader, CreateStartOptions } from './types'

interface Options extends CreateStartOptions { }

export default function start(renderLoader: RenderLoader, mountPointId: string, options: Options) {
  const fn = async () => {
    await (await options.lazyStoreManagerLoader()).initializing

    const renderer = renderLoader()

    const mountPoint = document.getElementById(mountPointId) as HTMLElement

    (await renderer).render(mountPoint)
  }

  return fn()
}
